@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Roboto-Regular;
  src: url(../src//assets//css/fonts/Roboto-Regular-14.ttf);
}

@font-face {
  font-family: Roboto-Regular;
  font-weight: 500;
  src: url(../src//assets//css/fonts/Roboto-Medium-12.ttf);
}

@font-face {
  font-family: Roboto-Regular;
  font-weight: bold;
  src: url(../src//assets//css/fonts/Roboto-Bold-3.ttf);
}

@font-face {
  font-family: Northwell;
  font-weight: 400;
  /* src: local("Northwell"), url('fonts/Northwell.ttf') format("truetype") */
  src: url(../src//assets//css/fonts/Northwell.ttf);
}

body * {
  margin: 0;
  font-family: Roboto-Regular, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body * {
  margin: 0;
  font-family: Roboto-Regular, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  width: 100%;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.react-flow__edge-path{
  stroke: rgb(135, 133, 133)!important;
  stroke-width:2!important;
}
.react-flow__node .react-flow__handle{
  background: transparent;
  height: 0px;
  width: 0px;
  border: none;
}
.react-flow__node:hover .react-flow__handle{
  background: #1a192b;
  border: 1px solid white;
  height: 6px;
  width: 6px;
}
.react-flow__edge.selected .react-flow__edge-path{
  stroke: red !important;
}
/* 
body * {
  font-family: Roboto-Regular, Roboto-Bold;
} */